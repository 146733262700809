import { render, staticRenderFns } from "./ListedContracts.vue?vue&type=template&id=32bfb137&scoped=true&"
import script from "./ListedContracts.vue?vue&type=script&lang=js&"
export * from "./ListedContracts.vue?vue&type=script&lang=js&"
import style0 from "./ListedContracts.vue?vue&type=style&index=0&id=32bfb137&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32bfb137",
  null
  
)

export default component.exports