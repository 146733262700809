<template>
  <div class="list-page">
    <b-container class="container-rda">
      <b-row>
        <b-col class="content">
          <b-row>
            <b-col>
              <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
            </b-col>
          </b-row>

          <b-row class="mb-5">
            <b-col>
              <h2 class="purple bold">Registro de Aprendizagem</h2>
            </b-col>
          </b-row>

          <b-row style="padding-bottom: 10px">
            <b-col md="9">
              <p class="purple">Filtre para obter resultados</p>
            </b-col>
            <b-col md="3">
              <b-form-group v-if="this.typeId !== 1">
                <b-form-checkbox v-model="futureTopics" switch @change="futureTopicsCallback">Adicionar tópicos
                  futuros</b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <SelectSimple v-if="allCycles.length" v-model="selectedCycle" label="Ciclos" :options="allCycles"
                :callback="selectCycle" />

              <span class="clear-field" v-if="selectedCycle" @click="clearSubject">Limpar filtro</span>
            </b-col>

            <b-col md="2" v-if="listSubjects.length > 0">
              <SelectSimple v-model="selectedDisciplinary" label="Disciplinas" :options="subjectsSel"
                :callback="selectDisciplinary" />
            </b-col>
            <b-col md="2" v-if="listSubjects.length > 0 && selectedDisciplinary">
              <SelectSimple v-model="selectedCalendar" label="Calendário" :options="calendarOptions"
                :callback="selectCalendar" />

              <span class="clear-field" v-if="selectedCalendar" @click="clearCalendar">Limpar filtro</span>
            </b-col>

            <b-col md="2" v-if="classrooms.length > 0 && this.typeId === 1">
              <b-form-group label="Turmas">
                <multiselect :value="selectedClassroom" :options="classrooms" :multiple="false" :close-on-select="true"
                  :clear-on-select="false" :preserve-search="true" open-direction="bottom" label="label"
                  placeholder="Selecione" track-by="id" selectLabel="" selectedLabel="" deselectLabel=""
                  @select="selectClassroom"></multiselect>

                <span class="clear-field" v-if="selectedClassroom" @click="clearClassroom">
                  Limpar filtro
                </span>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <SelectSimple v-model="this.typeSearch" label="Tipo" :options="optionsType" :callback="selectType" />
            </b-col>

            <b-col>
              <form @submit.prevent="search" v-if="this.subject > 0 && this.typeId === 1">
                <b-form-group label="Buscar alunos">
                  <b-input-group-prepend>
                    <b-form-input v-model="searchTerm" v-on:keyup.enter="search" type="search"
                      placeholder="Digite aqui" />
                  </b-input-group-prepend>
                  <b-input-group-text class="clear-fields" v-if="searchTerm != ''" v-b-tooltip.hover
                    title="Limpar Pesquisa">
                    <b-icon icon="x" @click="clearFields" />
                  </b-input-group-text>
                </b-form-group>
              </form>
            </b-col>
          </b-row>

          <b-row v-if="orderbyName.length && typeId === 1">
            <b-col>
              <b-container class="list-top" fluid>
                <b-row class="header-list-teacher" v-if="orderbyName.length">
                  <b-col md="7">
                    <p class="bold">Aluno</p>
                  </b-col>
                  <b-col md="2">
                    <p class="bold">Progresso</p>
                  </b-col>
                  <b-col>
                    <p class="bold">Avaliar</p>
                  </b-col>
                  <b-col>
                    <p class="bold">Visualizar</p>
                  </b-col>
                </b-row>
              </b-container>
              <b-container fluid>
                <b-row align-v="center" class="list-teacher" v-for="(student, index) in this.orderbyName" :key="index">
                  <b-col md="7">
                    <p>{{ student.User.name }}</p>
                  </b-col>

                  <b-col md="2">
                    <b-row class="row-progress" align-v="center" no-gutters>
                      <b-col>
                        <span>{{ parseInt(student.percentage) }}%</span>
                      </b-col>
                      <b-col cols="9">
                        <b-progress :value="student.percentage" :class="`${student.percentage <= 10 ? 'red' : ''}` ||
                          `${student.percentage < 60 ? 'orange' : ''}` ||
                          `${student.percentage < 90 ? 'yellow' : ''}` ||
                          `${student.percentage >= 90 ? 'green' : ''}`
                          "></b-progress>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col>
                    <RateIcon :class="{ inactive: !student.is_for_evaluation }" />
                  </b-col>

                  <b-col>
                    <router-link :to="{
                      name: 'registrar-aprendizagens',
                      params: { id: student.id, subject_id: subject },
                    }">
                      <ViewIcon />
                    </router-link>
                  </b-col>
                </b-row>

                <b-row align-v="center" class="list-teacher" v-if="wasRequestMade && !orderbyName.length">
                  <b-col>Nenhum resultado encontrado</b-col>
                </b-row>

                <b-row class="align-center" v-else-if="count > perPage && wasRequestMade">
                  <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="count"
                    aria-controls="teachersList" last-text first-text>
                    <template #prev-text>
                      <LeftArrowIcon class="left" />
                    </template>
                    <template #next-text>
                      <LeftArrowIcon />
                    </template>
                  </b-pagination>
                </b-row>
              </b-container>
            </b-col>
          </b-row>

          <b-row v-if="this.typeId === 2" class="wrap-competence-selected">
            <b-col v-if="topicsSelected.length > 0 || studentsSelected.length > 0">
              <p class="bold">Selecionados</p>
              <b-container fluid class="box-content">
                <div>
                  <p class="bold">Tópicos</p>
                  <ul class="competence-items-selected">
                    <li v-for="(topics, index) in topicsSelected" :key="index">
                      <span> {{ topics.description }}</span>
                      <span class="remove-item" @click="removeTopics(index)"></span>
                    </li>
                  </ul>
                </div>
                <div>
                  <p class="bold">Alunos</p>
                  <div class="students-items-selected">
                    <div v-for="(student, index) in studentsSelected" :key="index"
                      @click="removeStudent(index, student)" class="img-student">
                      <figure :style="{
                        backgroundImage: `url(${student.User.photo})`,
                      }">
                        <span>
                          <ArrowUpStudents />
                        </span>
                      </figure>
                    </div>
                  </div>
                </div>
                <div class="wrap-btn-submit">
                  <b-col v-if="!futureTopics" class="boxStatus">
                    <p v-for="(status, index) in learningStatus" v-bind:key="index">
                      <span class="statusButton" v-if="statusSelected !== status" @click="selectStatus(status)">{{
                        status
                        }}</span>
                      <span v-if="statusSelected === status" class="statusButton active" @click="cleanStatus">
                        {{ status }}
                      </span>
                    </p>
                  </b-col>
                  <b-button class="btn large blue" v-if="
                    studentsSelected.length > 0 && topicsSelected.length > 0
                  " @click="register">{{ !futureTopics ? "Registrar" : "Adicionar tópicos" }}
                  </b-button>
                </div>
              </b-container>
            </b-col>
            <b-col v-else>
              <b-container fluid class="box-content alignTextBox" v-if="
                this.competencesList.length > 0 &&
                this.listSubjects.length > 0
              ">
                <p>Selecione os tópicos e os alunos.</p>
              </b-container>
            </b-col>
          </b-row>

          <b-row class="wrap-competence-selected" v-if="this.listSubjects.length === 0 && selectedCycle">
            <b-col>
              <b-container fluid class="box-content alignTextBox">
                <p>Não existe disciplinas para esta turma</p>
              </b-container>
            </b-col>
          </b-row>

          <b-row v-if="
            this.typeId === 2 &&
            this.competencesList.length > 0 &&
            this.listSubjects.length > 0
          " class="wrap-competence">
            <b-col>
              <b-container fluid>
                <div class="box-content">
                  <div class="col list">
                    <p class="bold">Campo de conhecimento</p>
                    <div class="col-content" v-for="(competence, index) in competencesList" :key="competence.id">
                      <Accordion :index="index + 1">
                        <div slot="title">
                          {{ competence.description }}
                        </div>

                        <div slot="content">
                          <ul class="list-competence">
                            <li v-for="(topics, indexTopic) in competence.Topics" :key="topics.id">
                              <p v-if="
                                !topicsSelected.find(
                                  (selected) => selected.id === topics.id
                                )
                              ">
                                <span>{{ index + 1 }}.{{ indexTopic + 1 }}</span>
                                {{ topics.description }}
                              </p>
                              <p v-else class="list-competence-selected">
                                <span>{{ index + 1 }}.{{ indexTopic + 1 }}</span>
                                {{ topics.description }}
                              </p>
                              <span v-if="
                                !topicsSelected.find(
                                  (selected) => selected.id === topics.id
                                )
                              " class="btn-plus" @click="
                                  addTopics(
                                    topics,
                                    index + 1 + '.' + (indexTopic + 1),
                                    indexTopic,
                                    index
                                  )
                                  ">+</span>
                            </li>
                          </ul>
                        </div>
                      </Accordion>
                    </div>
                  </div>
                  <div class="col students-list">
                    <p class="bold">Alunos</p>
                    <div class="col-content">
                      <p class="about">
                        Clique no(s) aluno(s) que deseja inserir na competência
                        selecionada.
                      </p>
                      <div class="wrap-search-students">
                        <form @submit.prevent="search">
                          <b-form-group label="Buscar alunos">
                            <b-input-group-prepend>
                              <b-form-input v-model="searchStudentList" v-on:keyup.enter="search" type="search"
                                placeholder="Buscar por aluno" />
                            </b-input-group-prepend>
                            <b-input-group-text class="clear-fields" v-if="this.searchStudentList != ''"
                              v-b-tooltip.hover title="Limpar Pesquisa">
                              <b-icon icon="x" @click="clearStudentList" />
                            </b-input-group-text>
                          </b-form-group>
                        </form>
                      </div>
                      <span class="empty-student-list" v-if="this.filteredStudentList.length <= 0">Nenhum aluno
                        encontrado.</span>
                      <ul v-if="this.filteredStudentList.length > 0">
                        <li v-for="(item, index) in this.filteredStudentList" :key="`student-${index}`"
                          @click="selectStudent(item)">
                          <div class="img-student">
                            <figure :style="{
                              backgroundImage: 'url(' + item.User.photo + ')',
                            }">
                              <span>
                                <ArrowUpStudents />
                              </span>
                            </figure>
                          </div>
                          <div class="info-studante">
                            <span>{{ item.User.name }}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-container>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Accordion from "@/components/Accordion.vue";
import SelectSimple from "@/components/inputs/SelectSimple.vue";
import RateIcon from "@/components/svg/RateIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon";
import ArrowUpStudents from "@/components/svg/ArrowUpStudents";
import ViewIcon from "@/components/svg/ViewIcon2.vue";
import httpRda from "../../http-rda";
export default {
  name: "TopicsStudentsList",
  components: {
    SelectSimple,
    RateIcon,
    LeftArrowIcon,
    ViewIcon,
    Accordion,
    ArrowUpStudents,
  },

  data() {
    return {
      futureTopics: false,
      descOrder: false,
      wasRequestMade: false,
      cycle_id: 1,
      subject: null,
      progress: null,
      searchTerm: localStorage.getItem("searchTerm")
        ? localStorage.getItem("searchTerm")
        : "",
      loading: false,
      typeSearch: null,
      currentPage: localStorage.getItem("currentPage")
        ? localStorage.getItem("currentPage")
        : 1,
      statusSelected: null,
      count: 0,
      perPage: 20,
      typeId: null,
      learningStatus: ["EE", "AN", "A", "*", "#", ""],
      selectedCycle: null,
      selectedCycleAux: localStorage.getItem("selectedCycle"),
      listSubjects: [],
      selectedClassroom: null,
      selectedClassroomAux: localStorage.getItem("selectedClassroom"),
      selectedDisciplinary: null,
      selectedDisciplinaryAux: localStorage.getItem("selectedDisciplinary"),
      breadcrumb: [
        {
          text: "Aprendizagem",
          href: "/aprendizagem",
        },
        {
          text: "Registro de Aprendizagem",
          href: "/aprendizagem/conteudos",
          active: true,
        },
      ],
      students: [],
      optionsType: [
        {
          id: 1,
          label: "Alunos",
        },
        {
          id: 2,
          label: "C. de conhecimento",
        },
      ],
      searchStudentList: "",
      classrooms: [],
      classroomsBkp: [],
      topicsSelected: [],
      studentsForCycle: [],
      studentsSelected: [],
      competencesList: [],
      allCycles: [],
      selectedCalendar: null,
      calendarOptions: [],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear().toString().substr(-2);
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  mounted() {
    this.getCycles();
    this.typeSearch = this.optionsType[0];
    this.typeId = this.optionsType[0].id;
  },

  created() {
    if (this.selectedCycleAux != null) {
      this.selectCycle(JSON.parse(this.selectedCycleAux));
    }
  },

  computed: {
    subjectsSel() {
      return this.listSubjects;
    },

    orderbyName() {
      return [...this.students].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];
        if (a.name < b.name) return order[0];
        else if (a.name > b.name) return order[1];
        return 0;
      });
    },

    filteredStudentList() {
      return this.studentsForCycle.filter((item) => {
        let name = item.User.name.toLowerCase();
        name = name.replace(/[aáàãäâ]/, "a");
        name = name.replace(/[eéèëê]/, "e");
        name = name.replace(/[iíìïî]/, "i");
        name = name.replace(/[oóòõöô]/, "o");
        name = name.replace(/[uúùüû]/, "u");
        if (item.is_active) {
          return name.includes(this.searchStudentList.toLowerCase());
        }
      });
    },
  },

  methods: {
    ...mapMutations({
      resetStudents: "learning/RESET_STUDENTS_BY_SUBJECT",
    }),

    selectCalendar(value) {
      this.selectedCalendar = value;

      this.selectedClassroom = null;

      this.classrooms = [];

      const classroomsAux = this.classroomsBkp.filter((item) => {
        return item.calendar_id === this.selectedCalendar.id;
      });

      this.classrooms = classroomsAux.map((item) => {
        return {
          id: item.id,
          label: item.designation,
        };
      });

      this.getSubjects();
    },

    async getCalendars() {
      const calendars = await httpRda.get("calendars");
      const filter = calendars.data.calendars.map((item) => {
        return {
          id: item.id,
          label: item.designation,
        };
      });
      this.calendarOptions = filter;
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    search() {
      this.getSubjects(localStorage.getItem("currentPage"));
      this.currentPage = localStorage.getItem("currentPage");
    },

    clearFields() {
      this.searchTerm = "";
      localStorage.removeItem("searchTerm");
      this.currentPage = 1;
      localStorage.setItem("currentPage", this.currentPage);
      this.getStudents();
      this.futureTopics = false;
      this.selectedClassroom = null;
      this.classrooms = [];
    },

    clearStudentList() {
      this.searchStudentList = "";
    },

    clearClassroom() {
      this.selectedClassroom = null;
      this.getSubjects();
    },

    clearCalendar() {
      this.selectedCalendar = null;
      this.getSubjects();
    },

    clearSubject() {
      this.subject = null;
      this.currentPage = 1;
      localStorage.setItem("currentPage", this.currentPage);
      this.wasRequestMade = false;
      this.competencesList = [];
      this.studentsSelected = [];
      this.topicsSelected = [];
      this.studentsForCycle = [];
      this.resetStudents();
      this.selectedCycle = null;
      this.listSubjects = [];
      this.students = [];
      this.selectedClassroom = null;
      this.classrooms = [];
      this.selectedClassroom = null;

      localStorage.removeItem("searchTerm");
      this.searchTerm = "";

      localStorage.removeItem("selectedCycle");
      this.selectedCycleAux = null;

      localStorage.removeItem("selectedDisciplinary");
      this.selectedDisciplinaryAux = null;

      localStorage.removeItem("selectedClassroom");
      this.selectedClassroomAux = null;
    },

    async getSubjects(page = 1) {
      let pageAux = page;

      if (page == 1 || !page) {
        pageAux = 1;
        localStorage.setItem("currentPage", 1);
        this.currentPage = pageAux;
      }

      if (this.typeId === 2 && this.subject) {
        const subjects = await httpRda.get(
          `subjects/${this.subject}?include=topics`,
          {
            params: {
              calendar: this.selectedCalendar,
            },
          }
        );

        let students = [];
        if (this.futureTopics) {
          students = await httpRda.get("students");
        } else {
          students = await httpRda.get(
            `students?cycle=${subjects.data.subject.cycle_id}`
          );
        }

        this.count = subjects.data.count;
        this.wasRequestMade = true;
        let competences = subjects.data.subject.Competences;

        this.competencesList = competences;
        this.studentsForCycle = students.data.students;
      } else if (this.subject) {
        const students = await httpRda.get(
          `subjects/${this.subject}/students?search=${this.searchTerm}&limit=${this.perPage}&page=${pageAux}`,
          {
            params: {
              calendar: this.selectedCalendar ? this.selectedCalendar.id : null,
              classroom: this.selectedClassroom
                ? this.selectedClassroom.id
                : null,
            },
          }
        );
        this.count = students.data.count;
        this.wasRequestMade = true;

        let studentsData = students.data.students;

        this.students = studentsData.filter((item) => item.User !== null);
      }
    },

    async getCycles() {
      const cycles = await httpRda.get("cycles?include=subjects,classrooms&includeStudents=false");
      const filter = cycles.data.cycles.map((item) => {
        item.label = item.designation;
        delete item.designation;
        return item;
      });
      this.allCycles = filter;
      this.selectedDisciplinary = null;
    },

    async selectCycle(params) {
      await this.getCalendars();

      const cycleIdLocalStorage =
        localStorage.getItem("selectedCycle") &&
          localStorage.getItem("selectedCycle").length > 0
          ? JSON.parse(localStorage.getItem("selectedCycle")).id
          : null;

      if (cycleIdLocalStorage !== params.id) {
        localStorage.setItem("currentPage", 1);
        this.currentPage = 1;
      }

      localStorage.setItem("selectedCycle", JSON.stringify(params));

      this.listSubjects = [];
      this.selectedCycle = params;

      let response = await httpRda
        .get(`cycles/${params.id}?include=subjects,classrooms`)
        .then((res) => {
          return res.data.cycle;
        });

      let subjects = response.Subjects;

      subjects = subjects.map((item) => {
        item.label = item.designation;
        delete item.designation;
        return item;
      });

      this.classroomsBkp = [];
      this.classroomsBkp = response.Classrooms;
      this.selectedCalendar = null;

      this.listSubjects = subjects;
      this.studentsSelected = [];
      this.topicsSelected = [];

      const parsedSelectedDisciplinaryAux =
        this.selectedDisciplinaryAux !== null
          ? JSON.parse(this.selectedDisciplinaryAux)
          : null;

      if (
        parsedSelectedDisciplinaryAux == null ||
        parsedSelectedDisciplinaryAux.cycle_id !== params.id
      ) {
        localStorage.removeItem("selectedDisciplinary");

        this.selectedDisciplinaryAux = null;
        this.selectedDisciplinary = null;
      }

      if (subjects.length > 0) {
        if (this.selectedDisciplinaryAux == null) {
          this.selectedDisciplinary = subjects[0];
          this.subject = subjects[0].id;

          await this.getSubjects(localStorage.getItem("currentPage"));
        } else {
          this.subject = parsedSelectedDisciplinaryAux.id;

          await this.getSubjects(localStorage.getItem("currentPage"));

          this.selectedDisciplinary = parsedSelectedDisciplinaryAux;
        }
      } else {
        this.students = [];
        this.subject = null;
      }
    },

    async selectClassroom(params) {
      localStorage.setItem("selectedClassroom", JSON.stringify(params));

      this.selectedClassroom = params;
      await this.getSubjects();
    },

    async selectDisciplinary(params) {
      localStorage.setItem("selectedDisciplinary", JSON.stringify(params));

      this.topicsSelected = [];
      this.selectedDisciplinary = params;
      this.subject = params.id;

      await this.getSubjects();
    },

    selectSubject(value) {
      this.subject = value.id;
      this.getSubjects();
      this.wasRequestMade = true;
    },

    normalizeString(str) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },

    formatDateToUs(date) {
      return new Intl.DateTimeFormat("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
        .format(new Date(date))
        .replaceAll(". ", "/")
        .replace(".", "");
    },

    futureTopicsCallback() {
      this.studentsSelected = [];
      this.topicsSelected = [];
      this.statusSelected = null;
      this.getSubjects();
    },

    selectStatus(status) {
      this.statusSelected = status;
    },
    cleanStatus() {
      this.statusSelected = "";
    },

    selectType(value) {
      this.typeSearch = value;
      this.typeId = value.id;
      this.getSubjects();
    },

    removeTopics(index) {
      this.topicsSelected.splice(index, 1);
    },

    selectStudent(student) {
      this.studentsSelected.push(student);
      this.studentsForCycle.splice(this.studentsForCycle.indexOf(student), 1);
    },

    removeStudent(index, student) {
      this.studentsSelected.splice(index, 1);
      this.studentsForCycle.push(student);
      this.studentsForCycle.sort((a, b) => {
        if (a.User.name < b.User.name) return -1;
        else if (a.User.name > b.User.name) return 1;
        return 0;
      });
    },

    addTopics(topic, topicIndex) {
      const topicsObject = {
        id: topic.id,
        name: topic.name,
        description: topicIndex + " - " + topic.description,
        competences: topic.competences,
      };
      this.topicsSelected.push(topicsObject);
    },
    async register() {
      const evaluation = this.statusSelected;

      const topics = this.topicsSelected.map((item) => {
        return item.id;
      });

      const students = this.studentsSelected.map((item) => {
        return item.id;
      });

      if (this.futureTopics) {
        await httpRda
          .post("students-insert-future-topics", {
            topics,
            students,
          })
          .then((res) => {
            this.$swal.fire({
              title: "",
              text: res.data.msg,
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });

            this.topicsSelected = [];
            this.studentsSelected = [];
            this.statusSelected = null;
          })
          .catch(() => {
            this.$swal.fire({
              title: "Alerta",
              text: "Não foi possível registrar.",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        await httpRda
          .patch("studentstopicsupdate", {
            evaluation,
            topics,
            students,
          })
          .then((res) => {
            this.$swal.fire({
              title: "",
              text: res.data.msg,
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });

            this.topicsSelected = [];
            this.studentsSelected = [];
            this.statusSelected = null;
          })
          .catch(() => {
            this.$swal.fire({
              title: "Alerta",
              text: "Não foi possível atualizar.",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },
  },

  watch: {
    currentPage() {
      localStorage.setItem("currentPage", this.currentPage);
      this.getSubjects(this.currentPage);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    searchTerm() {
      localStorage.setItem("searchTerm", this.searchTerm);
    },
  },

  destroyed() {
    this.resetStudents();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.alignTextBox {
  text-align: center;

  p {
    margin: 0;
  }
}

.home {
  height: 100%;
}

.list-top {
  margin-top: 30px;
  margin-bottom: 30px;
}

.progress {
  border-radius: 30px;
  height: 7px;

  .progress-bar {
    background-color: $purple;
  }

  &.orange {
    .progress-bar {
      background-color: $orange2;
    }
  }

  &.yellow {
    .progress-bar {
      background-color: $yellow;
    }
  }

  &.red {
    .progress-bar {
      background-color: $red;
    }
  }

  &.green {
    .progress-bar {
      background-color: $green2;
    }
  }
}

.container-rda {
  padding-bottom: 0;
}

.learning-date {
  background-color: $purple;
  border-radius: 6px;
  color: $white;
  line-height: 28px;
  max-width: 82px;
  text-align: center;
  width: 100%;
}

.boxStatus {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  p {
    margin: 10px;
  }
}

.statusButton {
  background-color: $grey5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: $white;
  font-size: 18px;
  line-height: 28px;
  width: 34px;
  height: 34px;
  text-align: center;
  cursor: pointer;

  &.active {
    background-color: $blue;
  }
}

.list-page {
  .list-teacher:hover {
    cursor: unset;
  }

  .list-actions {
    padding: 10px 0;
    cursor: pointer;

    .evaluation:not(.evaluated) path {
      fill: gray;
    }
  }
}

.inactive {
  filter: grayscale(1);
}

.empty-content {
  font-size: 18px;
  text-align: center;
  min-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;

  b {
    color: #b23066;
  }
}

.wrap-competence {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 0;

  .container-fluid {
    margin-left: -15px;
    margin-right: -15px;
  }

  .box-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
    row-gap: 15px;
    margin-right: -15px;
    margin-left: -15px;
    box-sizing: border-box;

    .col {
      .col-content {
        background: #fff;
        border: 1px solid #b3b3b3;
        border-radius: 10px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        margin-bottom: 10px;
        height: max-content;
      }

      &.list {
        flex-grow: 2.5;
      }
    }
  }

  .list-competence {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 9px;

    li {
      width: 100%;
      border: 1px dashed transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 13px;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;

      &:hover,
      &:focus {
        border-color: #ccc;
      }

      &.selected {
        p {
          opacity: 0.4;
        }

        .btn-plus {
          color: #b3b3b3;
          background-color: #e6e6e6;
        }
      }

      p {
        flex: 1;
        color: #000;
        font-size: 18px;
        line-height: 20px;
        padding: 0;
        margin: 0;
      }

      .btn-plus {
        background: #68c5b7;
        color: #fff;
        font-size: 20px;
        line-height: 20px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
    }
  }

  .list-competence-selected {
    width: 100%;
    border: 1px dashed #b23066;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    color: #b23066;
  }

  .students-list {
    .about {
      margin-bottom: 0;
    }

    form,
    fieldset {
      margin-bottom: 0;
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      max-height: 300px;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
      overflow-y: auto;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        list-style: none;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          .img-student {
            figure {
              &:after {
                opacity: 1;
                transition: 0.1s all ease-in-out;
              }

              span {
                top: 0;
                opacity: 1;
                transition: 0.2s all ease-in-out;
                transition-delay: 0.1s;
              }
            }
          }
        }

        .img-student {
          width: 48px;
          height: 48px;

          figure {
            background-color: #ccc;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: inherit;
            height: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            overflow: hidden;
            position: relative;

            &:after {
              content: "";
              background: linear-gradient(0deg,
                  rgba(178, 48, 102, 0.7),
                  rgba(178, 48, 102, 0.7));
              width: inherit;
              height: inherit;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 0;
              opacity: 0;
              transition: 0.1s all ease-in-out;
            }

            span {
              position: relative;
              top: 20px;
              z-index: 1;
              opacity: 0;
              transition: 0.2s all ease-in-out;
            }
          }
        }

        .info-studante {
          span {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .empty-student-list {
    text-align: center;
  }
}

.wrap-competence-selected {
  width: 100%;
  padding-top: 30px;

  .box-content {
    width: 100%;
    background: #fff;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  .competence-items-selected {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin: 0;
    padding: 0;

    li {
      background-color: #f2f2f2;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 16px;
      list-style: none;

      .remove-item {
        background: #ef6549;
        color: #fff;
        font-size: 20px;
        line-height: 20px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }

        &:before {
          content: "";
          background: #fff;
          width: 10px;
          height: 2px;
          display: block;
        }
      }
    }
  }

  .students-items-selected {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    row-gap: 10px;

    .img-student {
      width: 56px;
      height: 56px;
      cursor: pointer;

      &:hover {
        figure {
          &:after {
            opacity: 1;
            transition: 0.1s all ease-in-out;
          }

          span {
            top: 0;
            opacity: 1;
            transition: 0.2s all ease-in-out;
            transition-delay: 0.1s;
          }
        }
      }

      figure {
        background-color: #b3b3b3;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: inherit;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        overflow: hidden;
        position: relative;

        &:after {
          content: "";
          background: linear-gradient(0deg,
              rgba(178, 48, 102, 0.7),
              rgba(178, 48, 102, 0.7));
          width: inherit;
          height: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          opacity: 0;
          transition: 0.1s all ease-in-out;
        }

        span {
          position: relative;
          top: 20px;
          z-index: 1;
          opacity: 0;
          transition: 0.2s all ease-in-out;
          transform: rotate(-180deg);
        }
      }
    }
  }

  .wrap-btn-submit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #d8d8d8;
    padding-top: 15px;

    button {
      width: 292px;
    }
  }
}
</style>
