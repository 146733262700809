<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">
              Contratos
              <span class="qty">({{ count }} listados)</span>
            </h2>
          </b-col>
          <b-col class="text-right"></b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col md="2">
            <SelectStatus
              label="Situação"
              placeholder="Selecione"
              v-model="printed"
              :callback="selectStatus"
            ></SelectStatus>
          </b-col>
          <b-col md="2">
            <SelectCalendar
              label="Ano Letivo"
              placeholder="Selecione"
              v-model="calendar"
              :callback="callbackCalendars"
            ></SelectCalendar>
            <span
              class="clear-field"
              v-if="this.calendar != null"
              @click="clearCalendar"
              >Limpar filtro</span
            >
          </b-col>
          <b-col>
            <b-form-group label="Buscar Aluno">
              <b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Digite aqui"
                  v-model="searchTitle"
                  v-on:keyup.enter="
                    page = 1;
                    getList();
                  "
                ></b-form-input>
                <b-input-group-text
                  class="clear-fields"
                  v-if="searchTitle != ''"
                  v-b-tooltip.hover
                  title="Limpar Pesquisa"
                >
                  <b-icon icon="x" @click="clearFields" />
                </b-input-group-text>
              </b-input-group-prepend>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="order-grid-box">
              <p
                class="order-list"
                :class="{ desc: descOrder }"
                v-on:click="orderName"
              >
                Ordenar
                <OrderNameIcon />
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-container fluid>
              <LoadingList v-model="loading" text="Carregando lista" />
              <b-row class="column-list" v-if="count > 0 && isRequestSend">
                <b-col md="3">
                  <p class="bold">Aluno</p>
                </b-col>
                <b-col>
                  <p class="bold">Status</p>
                </b-col>
                <b-col>
                  <p class="bold">Anuidade</p>
                </b-col>
                <b-col>
                  <p class="bold">Vencimento</p>
                </b-col>
                <b-col>
                  <p class="bold">Ano letivo</p>
                </b-col>
                <b-col>
                  <p class="bold">Matrícula</p>
                </b-col>
                <b-col>
                  <p class="bold">Visualizar</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row
                align-v="center"
                class="list-contracts"
                v-for="enrollment in orderbyName"
                :key="enrollment.id"
              >
                <b-col md="3">
                  <p v-if="enrollment.EnrollmentStudent">
                    <abbr :title="enrollment.EnrollmentStudent.name">
                      {{ enrollment.EnrollmentStudent.name.substring(0, 40) }}
                    </abbr>
                  </p>
                </b-col>
                <b-col>
                  <p>{{ enrollment.signed_at != null ? "Ativo" : "-" }}</p>
                </b-col>
                <b-col>
                  <p v-if="enrollment.EnrollmentFinance">
                    <abbr :title="enrollment.EnrollmentFinance.payment">
                      {{
                        enrollment.EnrollmentFinance.payment.substring(0, 10)
                      }}
                    </abbr>
                  </p>
                  <p v-else>-</p>
                </b-col>
                <b-col>
                  <p v-if="enrollment.EnrollmentFinance">
                    {{ enrollment.EnrollmentFinance.expiration }}
                  </p>
                  <p v-else>-</p>
                </b-col>
                <b-col>
                  <p v-if="enrollment.Calendar">
                    {{ enrollment.Calendar.designation }}
                  </p>
                  <p v-else>-</p>
                </b-col>
                <b-col>
                  <p>{{ enrollment.id }}</p>
                </b-col>
                <b-col>
                  <router-link
                    :to="{
                      name: 'visualizar-contrato',
                      params: { id: enrollment.id },
                    }"
                  >
                    <ViewIcon />
                  </router-link>
                </b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="enrollments.length <= 0 && isRequestSend"
              >
                <b-col>Nenhum resultado encontrado</b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="!isRequestSend"
              >
                <b-col>Carregando</b-col>
              </b-row>
              <b-row
                class="align-center"
                v-else-if="count > perPage && isRequestSend"
              >
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="count"
                  @change="changePage"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/enrollments.service";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import ViewIcon from "@/components/svg/ViewIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import SelectCalendar from "@/components/inputs/SelectCalendar.vue";
import SelectStatus from "@/components/inputs/SelectStatus.vue";
import LoadingList from "@/components/LoadingList.vue";
import httpRda from "../../http-rda";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  name: "lista-contratos",
  components: {
    OrderNameIcon,
    SelectCalendar,
    SelectStatus,
    ViewIcon,
    LeftArrowIcon,
    LoadingList,
  },
  data() {
    return {
      enrollments: [],
      descOrder: false,
      isRequestSend: false,

      calendar: null,
      printed: null,
      searchTitle: "",

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,

      contract: "actives",

      loading: true,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Contratos",
          href: "/contratos",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
    }),
    orderbyName() {
      const newList = [...this.enrollments];
      for (var i = 0; i < newList.length; i++) {
        if (newList[i].EnrollmentStudent === null) {
          newList.splice(i, 1);
          i--;
        }
      }

      newList.sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.EnrollmentStudent.name < b.EnrollmentStudent.name)
          return order[0];
        else if (a.EnrollmentStudent.name > b.EnrollmentStudent.name)
          return order[1];

        return 0;
      });

      return newList;
    },
  },

  methods: {
    async signEnrollment(enrollmentId) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você irá marcar o contrato como assinado!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await httpRda
                .patch(`/enrollments/${enrollmentId}`, {
                  signed_at: moment()
                    .tz("America/Sao_Paulo")
                    .format("YYYY-MM-DD"),
                })
                .then(() => {
                  this.getList();
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel assinar!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel assinar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },

    getRequestParams(searchTitle, page, perPage, calendar, printed) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (calendar) {
        params["calendar_id"] = calendar;
      }

      if (printed) {
        params["signed"] = printed;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.calendar,
        this.printed
      );

      this.loading = true;

      DataService.listForSecretary(params)
        .then((response) => {
          this.enrollments = response.data.enrollments;
          this.count = response.data.count;
          this.isRequestSend = true;
          this.loading = false;
        })
        .catch((error) => {
          return error.data;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    callbackCalendars({ id }) {
      this.calendar = id;
      this.page = 1;
      this.getList();
    },

    getActives(printed) {
      this.printed = printed;
      this.contract = "actives";
      this.page = 1;
      this.getList();
    },

    selectStatus({ value }) {
      this.printed = value;
      this.status = value;
      this.page = 1;
      this.getList();
    },

    getInactives(printed) {
      this.printed = printed;
      this.contract = "inactives";
      this.page = 1;
      this.getList();
    },

    clearFields() {
      this.searchTitle = "";
      this.page = 1;
      this.getList();
    },

    clearPrinted() {
      this.printed = "true";
      this.page = 1;
      this.getList();
    },

    clearCalendar() {
      this.calendar = null;
      this.page = 1;
      this.getList();
    },

    isAllowed() {
      return (
        this.currentUser.role.includes("admin") ||
        this.currentUser.role.includes("secretary")
      );
    },
  },

  created() {
    this.getActives("true");
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.list-contracts {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  margin-right: -12px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    cursor: pointer;
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
}

.header-list {
  color: $grey1;
  p {
    margin: 0;
  }
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    line-height: 28px;
    max-width: 58px;
    text-align: center;
  }
}

.column-list {
  color: $grey1;
}

.status-box {
  margin: 0 0 60px;
}
</style>
