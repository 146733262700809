import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/enrollments", { params });
  }

  listForSecretary(params) {
    return http.get("/list-enrollments", { params });
  }

  getAllEnrollmentStudents(params) {
    return http.get("/enrollments/students", { params });
  }

  getPrinted(params) {
    return http.get("/enrollments?printed=true", { params });
  }

  get(id) {
    return http.get(`/enrollments/${id}`);
  }

  getEnrollments(id) {
    return http.get(`/responsibles/${id}/enrollments`);
  }

  getEnrollmentsActives() {
    return http.get(`/enrollments/actives`);
  }

  postFile(id, data) {
    return http.post(`/enrollments/${id}/files`, data);
  }

  updateCycle(id, data) {
    return http.patch(`/enrollments/${id}/student`, data);
  }

  getEnrollmentStudent(id) {
    return http.get(`/enrollment/${id}/student`);
  }

  create(data) {
    return http.post("/enrollments", data);
  }

  createStudent(enrollment_id, data) {
    return http.post(`/enrollments/${enrollment_id}/student`, data);
  }

  updateEnrollmentStudent(id, data) {
    return http.patch(`/enrollments/${id}/students`, data);
  }

  update(id, data) {
    return http.patch(`/enrollments/${id}`, data);
  }

  delete(id) {
    return http.delete(`/enrollments/${id}`);
  }

  findByTitle(designation) {
    return http.get(`/enrollments?search=${designation}`);
  }
}

export default new DataService();
